import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import TrackerHero from '../components/tracker/TrackerHero'
import TrackerSection from '../components/tracker/TrackerSection'
import TrackerBlock from '../components/tracker/TrackerBlock'
import TrackerPoints from '../components/tracker/TrackerPoints'
import TrackerTechnicalPoints1 from '../components/tracker/TrackerTechnicalPoints1'
import TrackerMap from '../components/tracker/TrackerMap'
import TrackerCaseStudy from '../components/tracker/TrackerCaseStudy'
import TrackerCaseStudyIntro from '../components/tracker/TrackerCaseStudyIntro'
import TrackerCaseStudyChallenge from '../components/tracker/TrackerCaseStudyChallenge'
import TrackerCaseStudySolution from '../components/tracker/TrackerCaseStudySolution'
import CallToAction from '../components/tracker/CallToAction'

export const query = graphql`
  query VisitTracker {
    dataJson {
        trackers {
        visit {
          id
          name
          title
          slug
          icon {
            publicURL
          }
          hero {
            title
            subtitle
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          block {
            title
            texts {
              id
              text
            }
          }
          points {
            id
            text
          }
          techincalPoints {
            title
            points {
              id
              text
            }
            texts {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          map {
            publicURL
          }
          caseStudy {
            title
            clientCase1: clientCase
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            challenge
            solution
          }
        }
      }
    }
  }
  `

export default function VisitTracker({ data: { dataJson: { trackers: { visit } } } }) {
  return (
    <Layout title='Visit Tracker' url='/visit-tracker' page='visit-tracker'>
      <TrackerHero hero={visit.hero} title={visit.title} icon={visit.icon} />
      <TrackerSection>
        <TrackerBlock block1={visit.block} />
        <TrackerPoints tracker={visit} />
        <TrackerTechnicalPoints1 techincalPoints={visit.techincalPoints} name={visit.name} />
        <TrackerMap tracker={visit} />
      </TrackerSection>
      <TrackerCaseStudy>
        <TrackerCaseStudyIntro tracker={visit} />
        <TrackerCaseStudyChallenge tracker={visit} />
        <TrackerCaseStudySolution tracker={visit} />
      </TrackerCaseStudy>
      <CallToAction />
    </Layout>
  )
}
